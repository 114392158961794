<template>
  <div>
    <CLink
      :href="'#/consumers/' + id"
      @mouseover="showHoverComponent = true"
      @mouseout="showHoverComponent = false"
    >
      {{ id }}
    </CLink>
    <transition name="fade">
      <CContainer
        v-if="showHoverComponent"
        class="hover-component"
        style="width: 30rem"
      >
        <CRow>
          <CCol sm="3"><b>ID</b></CCol>
          <CCol sm="6">{{ data.id }}</CCol>
          <div class="w-100"></div>

          <CCol sm="3"><b>Name</b></CCol>
          <CCol sm="6">{{ data.name ? data.name : '-' }}</CCol>
          <div class="w-100"></div>
          <CCol sm="3"><b>Email</b></CCol>
          <CCol sm="6">{{ data.email }}</CCol>
          <div class="w-100"></div>
          <CCol sm="3"><b>Phone</b></CCol>
          <CCol sm="6">{{ data.phone_number ? data.phone_number : '-' }}</CCol>
          <div class="w-100"></div>
          <CCol sm="3"><b>Status</b></CCol>
          <CCol sm="6">
            <CBadge :color="getUserBadge(data.status)">
              {{ data.status }}
            </CBadge></CCol
          >
          <div class="w-100"></div>
          <CCol sm="3"><b>LastLogin</b></CCol>
          <CCol sm="6"> {{ moment(data.last_login).fromNow() }} </CCol>
        </CRow>
      </CContainer>
    </transition>
  </div>
</template>

<script>
import UserProxy from '@/proxies/user.proxy';
import { UserBadge } from '@/utils/utils';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showHoverComponent: false,
      data: {},
    };
  },
  watch: {
    showHoverComponent: function () {
      if (this.showHoverComponent) {
        this.fetchData();
      }
    },
  },

  methods: {
    getUserBadge(status) {
      return UserBadge(status);
    },
    async fetchData() {
      let { data } = await UserProxy.find(this.id);
      this.data = data;
    },
  },
};
</script>

<style>
.hover-component {
  z-index: 9999;
  position: absolute;
  background-color: white;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
