<template>
  <div id="assignOtherProviderModel">
    <CModal
      :show.sync="showModal"
      size="xl"
      :centered="true"
      :closeOnBackdrop="false"
    >
      <CCardBody>
        <treeselect
          v-model="providerSelected"
          :multiple="false"
          :options="options"
          :load-options="loadOptions"
          placeholder="Select SP"
        />
      </CCardBody>

      <template #header>
        <h5 class="modal-title">
          Choose SP to assign for this BookingID: {{ bookingId }}
        </h5>
        <CButtonClose @click="closeModal" />
      </template>
      <template #footer>
        <CButton @click="$emit('close-modal')" color="secondary"
          >Cancel</CButton
        >
        <CButton @click="onSave" color="primary">Assign</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { Common } from '@/constants';
import ProviderProxy from '@/proxies/provider.proxy';
import { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect';

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    bookingId: {
      type: String,
      required: true,
    },
    providerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      datetimeFormat: Common.datetimeFormat,
      dateFormat: Common.dateFormat,
      loading: true,
      options: null,
      providerSelected: null,
    };
  },
  watch: {
    showModal: function () {},
  },
  methods: {
    async loadOptions({ action }) {
      if (action === LOAD_ROOT_OPTIONS) {
        this.loading = true;
        let query = {
          status: ['active'],
        };
        ProviderProxy.providers(query).then(({ data }) => {
          this.options = data
            ?.filter((x) => x.id != this.providerId)
            .map((x) => {
              return { id: `${x.id}`, label: `${x.id} - ${x.business_name}` };
            });

          this.loading = false;
        });
      }
    },

    async onSave() {
      let data = {
        selected: Number(this.providerSelected),
        providerId: this.providerId,
      };

      this.$emit('onSave', data);
      this.$emit('close-modal');
    },
    closeModal() {
      this.$emit('close-modal');
    },
  },
  name: 'AssignOtherProviderModel',
};
</script>
