<template>
  <div id="scheduleListModal">
    <CModal
      :show.sync="showModal"
      size="xl"
      :centered="true"
      :closeOnBackdrop="false"
    >
      <CCardBody>
        <CDataTable border :items="datas" :fields="fields">
          <td slot="type" slot-scope="{ item }">
            {{ item.type }}
          </td>
          <td slot="status" slot-scope="{ item }">
            <CBadge :color="getBadge(item.status)">
              {{ item.status }}
            </CBadge>
          </td>

          <td slot="job_id" slot-scope="{ item }">
            {{ item.payload.job_id ? item.payload.job_id : '-' }}
          </td>
          <td slot="next_process_at" slot-scope="{ item }">
            <span>
              <b>{{ item.next_process_at | moment(datetimeFormat) }}</b>
              <br />
              {{ moment(item.next_process_at).fromNow() }}
            </span>
          </td>
          <td slot="payload" slot-scope="{ item }">
            <template v-for="key in Object.keys(item.payload)">
              <span
                v-if="key == 'appointment' || key == 'appointment_old'"
                :key="key"
              >
                <div>
                  {{ key }} : {{ item.payload[key] | moment(datetimeFormat) }}
                </div>
              </span>
              <span
                v-else-if="
                  key == 'price' ||
                  key == 'old_price' ||
                  key == 'new_price' ||
                  key == 'refund_amount'
                "
                :key="key"
              >
                <div>{{ key }} : {{ item.payload[key] | currency100 }}</div>
              </span>
              <span v-else :key="key">
                <div>{{ key }} : {{ item.payload[key] }}</div>
              </span>
            </template>
          </td>
          <td slot="created_at" slot-scope="{ item }">
            {{ item.created_at | moment(datetime2Format) }}
          </td>
        </CDataTable>
      </CCardBody>

      <template #header>
        <h5 class="modal-title">Schedule of BookingID: {{ bookingId }}</h5>
        <CButtonClose @click="closeModal" />
      </template>
      <template #footer>
        <CButton @click="$emit('close-modal')" color="secondary"
          >Cancel</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import { Common } from '@/constants';
import { TaskQueueBadge } from '@/utils/utils';

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    bookingId: {
      type: String,
      required: true,
    },
    datas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      datetimeFormat: Common.datetimeFormat,
      datetime2Format: Common.datetime2Format,
      dateFormat: Common.dateFormat,
      fields: [
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'job_id',
          label: 'JobID',
        },
        {
          key: 'next_process_at',
          label: 'Process At',
        },
        {
          key: 'payload',
          label: 'Payload',
        },
        {
          key: 'created_at',
          label: 'Created At',
        },
      ],
    };
  },
  watch: {
    showModal: function () {},
  },
  methods: {
    getBadge: (status) => TaskQueueBadge(status),

    closeModal() {
      this.$emit('close-modal');
    },
  },
  name: 'ScheduleListModal',
};
</script>
