<template>
  <div id="viewBookingAuditComponent">
    <CSidebar :show="isShow" shadow aside size="xl" colorScheme="light">
      <div class="modal-header">
        <h4>{{ title }}</h4>
        <div class="mr-2">
          <CButtonClose @click="$emit('close')" />
        </div>
      </div>
      <div class="list-content p-3">
        <template v-for="item in historyData">
          <div :key="item.id" style="">
            <br />

            <CBadge :color="getBadge(item.status)">
              {{ item.status }}
            </CBadge>
            <span> - {{ item.created_at | moment(datetimeFormat) }}</span>
            <div style="font-size: small">
              <b>{{ item.action }}</b>
            </div>

            <span v-if="type == 'quote'">
              <span>quote_id: {{ item.quote_id }}</span>
              <br />
              <span>sp_id: {{ item.provider_id }}</span>
            </span>
            <span v-else></span>

            <template v-for="key in Object.keys(item.metadata)">
              <span
                v-if="key == 'appointment' || key == 'appointment_old'"
                :key="key"
              >
                <div>
                  {{ key }} : {{ item.metadata[key] | moment(datetimeFormat) }}
                </div>
              </span>
              <span
                v-else-if="
                  key == 'assign_provider_id' || key == 'previous_provider_id'
                "
                :key="key"
              >
                <div>
                  {{ key }} :
                  <CLink :href="'#/sp/' + item.metadata[key]">
                    {{ item.metadata[key] }}
                  </CLink>
                </div>
              </span>
              <span
                v-else-if="
                  key == 'assign_booking_id' || key == 'previous_booking_id'
                "
                :key="key"
              >
                <div>
                  {{ key }} :
                  <CLink :href="'#/bookings/' + item.metadata[key]">
                    {{ item.metadata[key] }}
                  </CLink>
                </div>
              </span>
              <span
                v-else-if="
                  key == 'price' ||
                  key == 'old_price' ||
                  key == 'new_price' ||
                  key == 'refund_amount'
                "
                :key="key"
              >
                <div>{{ key }} : {{ item.metadata[key] | currency100 }}</div>
              </span>
              <span v-else-if="key == 'time_slots'" :key="key">
                <div>{{ key }} : {{ item.metadata[key] }}</div>
              </span>
              <span
                v-else-if="
                  key == 'filter' ||
                  key == 'questions' ||
                  key == 'sub_category_name'
                "
                :key="key"
              >
              </span>
              <span v-else :key="key">
                <div>{{ key }} : {{ item.metadata[key] }}</div>
              </span>
            </template>
          </div>
        </template>
      </div>
    </CSidebar>
  </div>
</template>

<script>
import { Common } from '@/constants';
import { BookingBadge } from '@/utils/utils';

export default {
  name: 'ViewBookingAuditComponent',
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    historyData: {
      type: Array,
    },
  },
  data() {
    return {
      datetimeFormat: Common.datetimeFormat,
    };
  },
  methods: {
    getBadge: (status) => BookingBadge(status),
  },
};
</script>

<style lang="scss">
.list-content {
  max-height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
</style>
