<template>
  <div id="bookingNotificationModel">
    <CModal
      :show.sync="showModal"
      size="xl"
      :centered="true"
      :closeOnBackdrop="false"
    >
      <CCardBody>
        <CDataTable :loading="loading" border :items="datas" :fields="fields">
          <td slot="provider_id" slot-scope="{ item }">
            {{ item.provider_id ? item.provider_id : '-' }}
          </td>
          <td slot="consumer_id" slot-scope="{ item }">
            {{ item.consumer_id ? item.consumer_id : '-' }}
          </td>

          <td slot="payload" slot-scope="{ item }">
            <template v-for="key in Object.keys(item.payload)">
              <span
                v-if="key == 'appointment' || key == 'appointment_old'"
                :key="key"
              >
                <div>
                  {{ key }} : {{ item.payload[key] | moment(datetimeFormat) }}
                </div>
              </span>
              <span
                v-else-if="
                  key == 'price' ||
                  key == 'old_price' ||
                  key == 'new_price' ||
                  key == 'refund_amount'
                "
                :key="key"
              >
                <div>{{ key }} : {{ item.payload[key] | currency100 }}</div>
              </span>
              <span v-else :key="key">
                <div>{{ key }} : {{ item.payload[key] }}</div>
              </span>
            </template>
          </td>
          <td slot="created_at" slot-scope="{ item }">
            {{ item.created_at | moment(datetimeFormat) }}
          </td>
        </CDataTable>
      </CCardBody>

      <template #header>
        <h5 class="modal-title">Notifications of BookingID: {{ bookingId }}</h5>
        <CButtonClose @click="closeModal" />
      </template>
      <template #footer>
        <CButton @click="$emit('close-modal')" color="secondary"
          >Cancel</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import { Common } from '@/constants';
import { TaskQueueBadge } from '@/utils/utils';
import BookingProxy from '@/proxies/booking.proxy';

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    bookingId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      datetimeFormat: Common.datetimeFormat,
      dateFormat: Common.dateFormat,
      loading: true,
      fields: [
        {
          key: 'provider_id',
          label: 'Provider',
        },
        {
          key: 'consumer_id',
          label: 'Consumer',
        },
        {
          key: 'content',
          label: 'Content',
        },
        {
          key: 'created_at',
          label: 'Created At',
        },
      ],
      datas: [],
    };
  },
  watch: {
    showModal: function () {
      if (this.showModal == true) {
        this.loading = true;
        BookingProxy.adminNotificationOfBooking(this.bookingId).then(
          ({ data }) => {
            this.datas = data;
            this.loading = false;
          }
        );
      }
    },
  },
  methods: {
    getBadge: (status) => TaskQueueBadge(status),

    closeModal() {
      this.$emit('close-modal');
    },
  },
  name: 'BookingNotificationModel',
};
</script>
