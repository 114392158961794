var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"viewBookingAuditComponent"}},[_c('CSidebar',{attrs:{"show":_vm.isShow,"shadow":"","aside":"","size":"xl","colorScheme":"light"}},[_c('div',{staticClass:"modal-header"},[_c('h4',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"mr-2"},[_c('CButtonClose',{on:{"click":function($event){return _vm.$emit('close')}}})],1)]),_c('div',{staticClass:"list-content p-3"},[_vm._l((_vm.historyData),function(item){return [_c('div',{key:item.id},[_c('br'),_c('CBadge',{attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")]),_c('span',[_vm._v(" - "+_vm._s(_vm._f("moment")(item.created_at,_vm.datetimeFormat)))]),_c('div',{staticStyle:{"font-size":"small"}},[_c('b',[_vm._v(_vm._s(item.action))])]),(_vm.type == 'quote')?_c('span',[_c('span',[_vm._v("quote_id: "+_vm._s(item.quote_id))]),_c('br'),_c('span',[_vm._v("sp_id: "+_vm._s(item.provider_id))])]):_c('span'),_vm._l((Object.keys(item.metadata)),function(key){return [(key == 'appointment' || key == 'appointment_old')?_c('span',{key:key},[_c('div',[_vm._v(" "+_vm._s(key)+" : "+_vm._s(_vm._f("moment")(item.metadata[key],_vm.datetimeFormat))+" ")])]):(
                key == 'assign_provider_id' || key == 'previous_provider_id'
              )?_c('span',{key:key},[_c('div',[_vm._v(" "+_vm._s(key)+" : "),_c('CLink',{attrs:{"href":'#/sp/' + item.metadata[key]}},[_vm._v(" "+_vm._s(item.metadata[key])+" ")])],1)]):(
                key == 'assign_booking_id' || key == 'previous_booking_id'
              )?_c('span',{key:key},[_c('div',[_vm._v(" "+_vm._s(key)+" : "),_c('CLink',{attrs:{"href":'#/bookings/' + item.metadata[key]}},[_vm._v(" "+_vm._s(item.metadata[key])+" ")])],1)]):(
                key == 'price' ||
                key == 'old_price' ||
                key == 'new_price' ||
                key == 'refund_amount'
              )?_c('span',{key:key},[_c('div',[_vm._v(_vm._s(key)+" : "+_vm._s(_vm._f("currency100")(item.metadata[key])))])]):(key == 'time_slots')?_c('span',{key:key},[_c('div',[_vm._v(_vm._s(key)+" : "+_vm._s(item.metadata[key]))])]):(
                key == 'filter' ||
                key == 'questions' ||
                key == 'sub_category_name'
              )?_c('span',{key:key}):_c('span',{key:key},[_c('div',[_vm._v(_vm._s(key)+" : "+_vm._s(item.metadata[key]))])])]})],2)]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }