<template>
  <CCard>
    <CCardHeader>
      <b>{{ title }} </b>
    </CCardHeader>
    <CCardBody>
      <CDataTable border striped :items="items" :fields="fields">
        <td slot="amount" slot-scope="{ item }">
          {{ item.amount | currency100 }}
        </td>
        <td slot="status" slot-scope="{ item }">
          <CBadge :color="getBadge(item.status)">
            {{ item.status }}
          </CBadge>
        </td>
        <td slot="ongoing_date" slot-scope="{ item }">
          {{ item.ongoing_date | moment(datetimeFormat) }}
        </td>
        <td slot="completed_date" slot-scope="{ item }">
          <span v-if="item.completed_date?.includes('0001-01-01')">-</span>
          <span v-else>
            {{ item.completed_date | moment(datetimeFormat) }}
          </span>
        </td>
        <td slot="updated_at" slot-scope="{ item }">
          {{ item.updated_at | moment(datetimeFormat) }}
        </td>
        <td slot="created_at" slot-scope="{ item }">
          {{ item.created_at | moment(datetimeFormat) }}
        </td>
        <td slot="rating" slot-scope="{ item }">
          {{ item.rating ? item.rating.rate : '-' }}
        </td>
        <td slot="review" slot-scope="{ item }">
          {{ item.rating ? item.rating.review : '-' }}
        </td>

        <td slot="action" slot-scope="{ item }">
          <span
            v-if="
              booking_status == 'cancelled' || booking_status == 'reassigned'
            "
            >-</span
          >
          <span v-else>
            <span v-if="item.status == 'ongoing'">
              <CButton
                :disabled="item.status !== 'ongoing'"
                @click="onChangeToCompleted(item)"
                size="sm"
                color="success"
                class="mr-2"
                >Change to Completed</CButton
              >
            </span>
            <span v-else-if="item.status == 'completed'">
              <CButton
                :disabled="item.status !== 'completed'"
                @click="onChangeToOngoing(item)"
                size="sm"
                color="warning"
                class="mr-2"
                >Change to Ongoing</CButton
              >
            </span>
            <span v-else>-</span>
          </span>
        </td>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import { Common } from '@/constants';
import { BookingBadge } from '@/utils/utils';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    booking_status: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      datetimeFormat: Common.datetimeFormat,
      dateFormat: Common.dateFormat,
    };
  },
  methods: {
    getBadge: (status) => BookingBadge(status),
    onChangeToCompleted(job) {
      this.$emit('changeToCompleted', job);
    },
    onChangeToOngoing(job) {
      this.$emit('onChangeToOngoing', job);
    },
  },
};
</script>
